import { Parallax } from 'react-parallax';
import './index.css';

const SectionA = () => {
    return ( 
        <section className='A'>
            <div className='para' strength={500}>
            
                <div className='welcome'> 
                    <h1 className='text' strength={500}>
                    Custom Print Your T-shirts With Us.
                    </h1>
                </div>
              
            </div>
      
        </section>
     );
}
 
export default SectionA;